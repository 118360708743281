<template>
    <footer>
        <p>Ranek. Alguns direitos reservados.</p>
    </footer>
</template>

<script>
export default {
    name: 'TheFooter',
};
</script>

<style scoped>
footer {
    background: #87f;
    padding: 25px 0;
    text-align: center;
    font-size: 1.2rem;
    color: white;
    position: relative;
    top: 75px;
}
</style>
